import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";
import { CardsList } from "./views/CardsList";
import { RouteManager } from "./lib/navigate/routeManager";
import React, { useState, useEffect } from 'react';
import { LocalStorage } from "./infra/localStorage";
import { Loading } from "./components/Loading";
import sourceTracking from "./lib/sourceTracking";
import { Log } from "@infra/log";

function Router() {
    const [loading, setLoading] = useState(true);

    const loadRouter = async() => {
        let path = window.location.pathname;       
        setLoading(false);
        sourceTracking("useEffect", "Router");
    }

    useEffect(() => {
        loadRouter();
    },[]);

    return (
        loading ? <><Loading /></> : 
        <>
            {
                <BrowserRouter>
                    <RouteManager />
                    <Routes>
                        <Route path="/" element={<CardsList />} />
                    </Routes>
                </BrowserRouter>
            }
        </>

    );
}

export default Router;