import React, { useState, useEffect } from 'react';
import speakIcon from '@src/assets/icons/speak.png';
import editIcon from '@src/assets/icons/edit.png';
import removeIcon from '@src/assets/icons/remove.png';
import newIcon from '@src/assets/icons/new.png';
import saveIcon from '@src/assets/icons/save.png';
import cancelIcon from '@src/assets/icons/cancel.png';
import searchIcon from '@src/assets/icons/search.png';
import homeIcon from '@src/assets/icons/home.png';
import 'typeface-roboto';
import sourceTracking from '@src/lib/sourceTracking';
import ModalDialog from '@src/components/ModalDialog';
import axios from "axios";
import { Env } from "@infra/env";

export function CardsList(props: any) {
    const [modalSettings, setModalSettings] = useState({});
    const [items, setItems] = useState([]);
    const [searched, setSearched] = useState(false);
    const [resultsFound,setResultsFound] = useState(0);

    async function getApiInstance(){
        let baseURL = await Env.get("BASE_URL");

        //setFeedBack("Loading");
    
        let api = axios.create({
            baseURL: baseURL,
        });
        api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

        return api;
    }

    async function fetchData() {
        let api = await getApiInstance();

        await api({
            method: "get",
            url: "/fulllist?type=cards",
            headers: {}
          }).then(function (response: any) {
            return setItems(response.data);
        });

        //await new Promise(r => setTimeout(r, 1000));
        //setFeedBack("Loaded");
    }

    useEffect(() => {
        fetchData();
    },[]);

    const toggleDisplayInputs = (id: number) => {
        sourceTracking('toggleDisplayInputs', 'CardsList');

        let englishviewDiv = (document.getElementById('englishviewDiv'+id) as HTMLInputElement);

        if (englishviewDiv.style.display === 'inline' || englishviewDiv.style.display === ''){
            englishviewDiv.style.display = 'none';
            let englishEdit = (document.getElementById('englishEdit'+id) as HTMLInputElement);
            englishEdit.style.display = 'inline';

            let phoneticsDiv = (document.getElementById('phoneticsDiv'+id) as HTMLInputElement);
            phoneticsDiv.style.display = 'none';
            let phoneticsEdit = (document.getElementById('phoneticsEdit'+id) as HTMLInputElement);
            phoneticsEdit.style.display = 'inline';

            let portugueseDiv = (document.getElementById('portugueseDiv'+id) as HTMLInputElement);
            portugueseDiv.style.display = 'none';
            let portugueseEdit = (document.getElementById('portugueseEdit'+id) as HTMLInputElement);
            portugueseEdit.style.display = 'inline';
        } else {
            englishviewDiv.style.display = 'inline';
            let englishEdit = (document.getElementById('englishEdit'+id) as HTMLInputElement);
            englishEdit.style.display = 'none';

            let phoneticsDiv = (document.getElementById('phoneticsDiv'+id) as HTMLInputElement);
            phoneticsDiv.style.display = 'inline';
            let phoneticsEdit = (document.getElementById('phoneticsEdit'+id) as HTMLInputElement);
            phoneticsEdit.style.display = 'none';

            let portugueseDiv = (document.getElementById('portugueseDiv'+id) as HTMLInputElement);
            portugueseDiv.style.display = 'inline';
            let portugueseEdit = (document.getElementById('portugueseEdit'+id) as HTMLInputElement);
            portugueseEdit.style.display = 'none';
        }
    }

    const searchContentDisplay = async () => {
        sourceTracking('searchContent', 'CardsList');

        (document.getElementById('searchInput') as HTMLInputElement).value = "";
    
        let contentDiv = (document.getElementById('content') as HTMLInputElement);
        contentDiv.style.display = 'none';

        let searchContenttDiv = (document.getElementById('searchContent') as HTMLInputElement);
        searchContenttDiv.style.display = 'block';
    }

    const cancelSearchClick = () => {
        sourceTracking('cancelSearchClick', 'CardsList');

        let contentDiv = (document.getElementById('content') as HTMLInputElement);
        contentDiv.style.display = 'block';

        let newContentDiv = (document.getElementById('searchContent') as HTMLInputElement);
        newContentDiv.style.display = 'none';
    }

    const searchClickConfirm = async () => {
        sourceTracking('searchClick', 'CardsList');

        let searchContent = (document.getElementById('searchContent') as HTMLInputElement);
        searchContent.style.display = 'none';

        let content = (document.getElementById('content') as HTMLInputElement);
        content.style.display = 'block';

        let searchValue = (document.getElementById('searchInput') as HTMLInputElement).value;

        let api = await getApiInstance();

        await api({
            method: "get",
            url: "/search?query="+searchValue+"&type=cards"
        }).then(function (response: any) {
            setSearched(true);
            setResultsFound(response.data.length);
            return setItems(response.data);
        });
    }

    const saveClick = async (id: number) => {
        sourceTracking('saveClick', 'CardsList');

        let editBtn = (document.getElementById('editBtn'+id) as HTMLInputElement);
        editBtn.style.display = 'inline';
        let saveBtn = (document.getElementById('saveBtn'+id) as HTMLInputElement);
        saveBtn.style.display = 'none';

        let englishEdit = (document.getElementById('englishEdit'+id) as HTMLInputElement).value;
        let phoneticsEdit = (document.getElementById('phoneticsEdit'+id) as HTMLInputElement).value;
        let portugueseEdit = (document.getElementById('portugueseEdit'+id) as HTMLInputElement).value;

        let api = await getApiInstance();

        await api({
            method: "patch",
            url: "/update",
            data: {
                "id": id,
                "english": englishEdit,
                "phonetics": phoneticsEdit,
                "portuguese": portugueseEdit,
                "type": "cards"
            }
        });

        toggleDisplayInputs(id);
    }

    const editClick = (id: number) => {
        sourceTracking('editClick', 'CardsList');

        toggleDisplayInputs(id);

        let editBtn = (document.getElementById('editBtn'+id) as HTMLInputElement);
        editBtn.style.display = 'none';
        let saveBtn = (document.getElementById('saveBtn'+id) as HTMLInputElement);
        saveBtn.style.display = 'inline';
    }

    const confirmRemove = async (english: string) => {
        sourceTracking('confirmRemove', 'CardsList');

        let api = await getApiInstance();

        await api({
            method: "delete",
            url: "/delete?type=cards&english="+english
        });

        window.location.reload();
    }
    
    const removeClick = (english: string) => {
        sourceTracking('removeClick', 'CardsList');

        setModalSettings({
            open: true,
            title: 'Remover registro',
            message: 'Deseja realmente remover "'+english+'" ?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        confirmRemove(english);
                        setModalSettings({open: false});
                    }
                },
                {
                    label: 'Não',
                    onClick: async () => {
                        setModalSettings({open: false});
                    }
                }
            ]
        })
    }

    const newContent = () => {
        sourceTracking('newContent', 'CardsList');

        let contentDiv = (document.getElementById('content') as HTMLInputElement);
        contentDiv.style.display = 'none';

        let newContentDiv = (document.getElementById('newContent') as HTMLInputElement);
        newContentDiv.style.display = 'block';
    }

    const reloadPage = () => {
        setSearched(false);
        window.location.reload();
    }

    const saveNewConfirmClick = async () => {
        sourceTracking('saveNewConfirmClick', 'CardsList');

        let contentDiv = (document.getElementById('content') as HTMLInputElement);
        contentDiv.style.display = 'block';

        let newContentDiv = (document.getElementById('newContent') as HTMLInputElement);
        newContentDiv.style.display = 'none';

        let english = (document.getElementById('englishEditNew') as HTMLInputElement).value;
        let phonetics = (document.getElementById('phoneticsEditNew') as HTMLInputElement).value;
        let portuguese = (document.getElementById('portugueseEditNew') as HTMLInputElement).value;

        let api = await getApiInstance();

        await api({
            method: "post",
            url: "/insert",
            data: {
                "english": english,
                "phonetics": phonetics,
                "portuguese": portuguese,
                "type": "cards"
            }
        });
    }

    const cancelNewClick = () => {
        sourceTracking('cancelNewClick', 'CardsList');

        let contentDiv = (document.getElementById('content') as HTMLInputElement);
        contentDiv.style.display = 'block';

        let newContentDiv = (document.getElementById('newContent') as HTMLInputElement);
        newContentDiv.style.display = 'none';
    }

    return (
        <React.Fragment>
            <br/>
            <span style={{fontSize: '1.3em', fontFamily: 'Roboto', fontWeight: 'bold', color: '#555'}}>&nbsp;&nbsp;English | Phonetics | Portuguese</span>

            <div id='searchContent' style={{display: 'none', padding: '18px'}}>
                <input id="searchInput" type="text" style={{width: "100%", lineHeight: "25px", marginBottom: "10px"}}/>

                <div style={{position: 'absolute', right: '10px'}}>
                    <button className="toolBtn searchConfirmBtn" onClick={() => searchClickConfirm()}>
                        <img className="toolImg" src={searchIcon} alt='search' />
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className="toolBtn cancelSearch" onClick={() => cancelSearchClick()}>
                        <img className="toolImg" src={cancelIcon} alt='cancelar' />
                    </button>
                </div>
            </div>
            
            <div id='newContent' style={{display: 'none', padding: '18px'}}>
                <span style={{fontSize: '1em', fontFamily: 'Roboto', fontWeight: 'bold', color: '#555'}}>Novo registro</span>

                <div className="tag">EN</div>
                <textarea id="englishEditNew" className="englishEdit editArea" style={{display: 'inline'}}></textarea>

                <div className="tag"><img style={{position: 'relative', top: '5px'}} src={speakIcon} alt="speakLogo" /></div>
                <textarea id="phoneticsEditNew" className="phoneticsEdit editArea" style={{display: 'inline'}}></textarea>

                <div className="tag">PT</div>
                <textarea id="portugueseEditNew" className="portugueseEdit editArea" style={{display: 'inline'}}></textarea>

                <div style={{position: 'absolute', right: '10px'}}>
                    <button className="toolBtn cancelNewBtn" onClick={() => cancelNewClick()}>
                        <img className="toolImg" src={cancelIcon} alt='cancelar' />
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className="toolBtn saveNewBtn" onClick={() => saveNewConfirmClick()}>
                        <img className="toolImg" src={saveIcon} alt='save' />
                    </button>
                </div>
            </div>
            <div id="content" style={{padding: '10px'}}>
                <button className="toolBtn newBtn" onClick={() => newContent()}>
                    <img className="toolImg" src={newIcon} alt='new' />
                </button>
                <button className="toolBtn searchBtn" onClick={() => searchContentDisplay()}>
                    <img className="toolImg" src={searchIcon} alt='new' />
                </button>
                {searched ? <>
                    <button className="toolBtn homeBtn" onClick={() => reloadPage()}>
                        <img className="toolImg" src={homeIcon} alt='home' />
                    </button>
                    <br/>
                    <div>
                        Resultados encontrados: {resultsFound}
                    </div>
                </>: <></>}
                <br/>
                <br/>
                <br/>
                <br/>
                {
                    items.map((item: any) => {
                        return(
                            <React.Fragment key={item.id}>
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 1}} className={"divItem"+item.id}>
                                        <div className="tag">EN</div>
                                        <div id={"englishviewDiv"+item.id} className="englishContent viewDiv">{item.english}</div>
                                        <textarea id={"englishEdit"+item.id} className="englishEdit editArea" defaultValue={item.english}></textarea>
                                        
                                        <div className="tag" style={{position: 'relative', top: '-2px'}}>
                                            <img style={{position: 'relative', top: '5px'}} src={speakIcon} alt="speakLogo" />
                                        </div>
                                        <div id={"phoneticsDiv"+item.id} className="phoneticsContent">&nbsp;&nbsp;&nbsp;{item.phonetics}</div>
                                        <textarea id={"phoneticsEdit"+item.id} className="phoneticsEdit editArea" defaultValue={item.phonetics}></textarea>

                                        <div className="tag">PT</div>
                                        <div id={"portugueseDiv"+item.id}  className="portugueseContent viewDiv">{item.portuguese}</div>
                                        <textarea id={"portugueseEdit"+item.id} className="portugueseEdit editArea" defaultValue={item.portuguese}></textarea>
                                    </div>
                                </div>
                                <br/>
                                <div style={{position: 'absolute', right: '10px'}}>
                                    <button className="toolBtn removeBtn" onClick={() => removeClick(item.english)}>
                                        <img className="toolImg" src={removeIcon} alt='edit' />
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button id={"editBtn"+item.id} className="toolBtn editBtn" onClick={() => editClick(parseInt(item.id))}>
                                        <img className="toolImg" src={editIcon} alt='edit' />
                                    </button>
                                    <button id={"saveBtn"+item.id} className="toolBtn saveBtn" onClick={() => saveClick(parseInt(item.id))}>
                                        <img className="toolImg" src={saveIcon} alt='save' />
                                    </button>
                                    &nbsp;&nbsp;
                                </div>
                                <br/><br/>
                                <hr/>
                            </React.Fragment>
                        )
                    })
                }
                <ModalDialog settings={modalSettings} />
            </div>
        </React.Fragment>
    )
}
