import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import React, {useEffect} from 'react';

function RouteManager({ children }: any){
    const navigate = useNavigate();
    const location = useLocation();   

    window.addEventListener('popstate', function (event) {
        navigate(0);
    });
    //&& location.pathname !== "/login"
    return (
        <></>
    )
}

export { RouteManager };