import styled from 'styled-components';

const StyledButton = styled.button`
    /*margin-top: 10px;*/
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    background-color: rgba(86, 0, 158, 1);
    color: #fff;
    font-weight: bold;
    font-size: 1em;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;

    :active {
        background-color: rgba(86, 0, 158, 0.75);
    }
`

export { StyledButton };