import styled from 'styled-components';

const ModalBackground = styled.div`
    position: fixed;
    flex: 1;
    width: 100%;
    height: 100%;
    background: rgba(80, 80, 80, 0.9);
    color: #000;
    top: 0px;
    left: 0px;
    z-index: 999 !important;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ModalContainer = styled.div`
    border: 0px solid #000;
    width: 95%;
    padding-bottom: 40px;
    min-height: 170px;
    color: #000;
    background: #fff;
    content: "";
    display: table;
    clear: both;
`;

const ModalTitle = styled.span`
    position: relative;
    font-size: 1.3em;
    left: 20px;
    top: 20px;
    color: #000;
    font-weight: bold;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
`

const ModalMessage = styled.div`
    position: relative;
    font-size: 1em;
    margin: 0 20px;
    top: 40px;
    color: #000;
    float: left;
    font-weight: 100;
    line-height: 22px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    white-space: pre-line;
`

const ModalButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;

    /*display: inline-block;*/
    /*margin-left: 70px;*/
    /*border: 1px solid #000;*/
    height: 67px;
    width: 100%;
    position: relative;
    z-index: 999;
`;

export { ModalBackground, ModalContainer, ModalTitle, ModalMessage, ModalButtonsContainer };